import http from "../../node_modules/fe-infrastractures/src/utils/http";
import { globalEnv } from "fe-infrastractures";

export const getAADOauth2Url = () => {
  return http.get("/auth/idp/GetAADOauth2Url", {}, { loading: false, baseURL: globalEnv.VUE_APP_API_URL });
};


export const AADAuthorize = (data) => {
  data.sysId = 32;
  return http.post("/auth/idp/AADAuthorize", data, {
    loading: false,
    useBody: true,
    baseURL: globalEnv.VUE_APP_API_URL,
  });
};
